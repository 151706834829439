import scrollIntoView from 'smooth-scroll-into-view-if-needed';

const scrollToElement = (scrollTo) => {
  if (!scrollTo) return null;
  // remove trailing slash from querystring (form/ becomes form)
  const strippedScrollTo = scrollTo.replace(/\/$/, '');
  const element = document.getElementById(strippedScrollTo);
  if (!element) return null;
  return scrollIntoView(element, { behavior: 'smooth', block: 'start' });
};

export default scrollToElement;