const washedAiData = (data, aiData) => {
  let stringifiedData = JSON.stringify(data);
  const aiDataKeys = Object.keys(aiData);
  aiDataKeys.forEach((key) => {
    const value = aiData?.fields?.[key] || aiData?.[key]; // use key to access value
    const valueWithBrs =
      value && typeof value === 'string'
        ? value.replace(/(\r\n|\n|\r)/gm, '<br>')
        : encodeURIComponent(JSON.stringify(value));
    const regexPattern = `\\[${key}\\]`;
    const regex = new RegExp(regexPattern, 'gm');
    stringifiedData = stringifiedData.replace(regex, valueWithBrs); // assign the updated value to stringifiedSliceData
  });
  return JSON.parse(stringifiedData);
};

export default washedAiData;